"use strict";

/**
 * Imports
 * =============================================================================
 */
import { EventEmitter } from "./../../managers";
import { RootAnimations as Animation } from "./../../animations";

/**
 * Class
 * =============================================================================
 */
class Root {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		this.el = el;

		this.initBinds();
		this.init();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	initBinds() {
		this.handleNavOpen = this.handleNavOpen.bind(this);
		this.handleNavClose = this.handleNavClose.bind(this);
		this.handleResize = this.handleResize.bind(this);
		this.handleTopScroll = this.handleTopScroll.bind(this);
		this.handleRecoverScroll = this.handleRecoverScroll.bind(this);
	}

	init() {
		this.animation = new Animation();
	}

	initEvents() {
		EventEmitter.handleAdd("nav-open", this.handleNavOpen);
		EventEmitter.handleAdd("nav-close", this.handleNavClose);
		EventEmitter.handleAdd("nav-resize", this.handleResize);
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleNavOpen() {
		this.el.classList.add("has-nav-open");

		this.isNavOpen = true;

		this.savedScrollPosition = window.pageYOffset;

		this.animation.hideContent({
			rootMain: this.el.querySelector(".l-root__main"),
		});

		this.scrollToTopTimeout = setTimeout(this.handleTopScroll, 800);
	}

	handleNavClose() {
		this.animation.showContent({
			rootMain: this.el.querySelector(".l-root__main")
		}, () => {
			this.el.classList.remove("has-nav-open");
			this.isNavOpen = false;
		});

		this.scrollToPositionTimeout = setTimeout(this.handleRecoverScroll, 500);
	}

	handleResize() {
		if (!this.isNavOpen) {
			return;
		}

		this.animation.updateContent({
			rootMain: this.el.querySelector(".l-root__main")
		});
	}

	handleTopScroll() {
		window.scrollTo(0, 0);

		this.handleClearAllTimeouts();
	}

	handleRecoverScroll() {
		window.scrollTo(0, this.savedScrollPosition);

		this.handleClearAllTimeouts();
	}

	handleClearAllTimeouts() {
		clearTimeout(this.scrollToTopTimeout);
		this.scrollToTopTimeout = null;

		clearTimeout(this.scrollToPositionTimeout);
		this.scrollToPositionTimeout = null;
	}
}

export default Root;
