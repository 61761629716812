"use strict";

/**
 * Imports
 * =============================================================================
 */
import "./vendors/polyfills";

import { Document } from "./managers";

import {
	Root,
	Hamburger,
	ProfileMobileMenu,
	Nav,
	DottedGrid,
	Header,
} from "./modules";

import { dom } from "./utils";

/**
 * Main initialise
 * =============================================================================
 */
const init = () => {
	// Set fps of the animation engine
	TweenLite.ticker.fps(40);

	// Initialise all modules
	dom.init(".js-root", Root);
	dom.init(".js-hamburger", Hamburger);
	dom.initAll(".js-profile-mobile-menu", ProfileMobileMenu);
	dom.init(".js-nav", Nav);
	dom.initAll(".js-dotted-grid", DottedGrid);
	dom.init(".js-header", Header);
};

/**
 * Execute main initialise when the Document is ready
 * =============================================================================
 */
(() => {
	Document.handleReadyState(init);
})();
