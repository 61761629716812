"use strict";

/**
 * Class
 * =============================================================================
 */
class HackerText {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		this.el = el;

		this.settings = {
			interval: 200,
			duration: 1000
		};

		this.initBinds();
		this.init();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.letters = ["&", "@", "%", "!", "?", "#", "~", "$", "â‚¬", "*", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
		this.text = this.el.textContent || this.el.innerText;
	}

	initBinds() {
		this.handleMouseOver = this.handleMouseOver.bind(this);
	}

	initEvents() {
		this.el.addEventListener("mouseover", this.handleMouseOver);
	}

	getRandomNumber(max, min) {
		return Math.floor(Math.random() * (+max - +min)) + +min;
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleMouseOver() {
		if(!this.text) {
			return;
		}

		const glitch = setInterval(() => {
			this.handleRandomize(this.text);
		}, this.settings.interval);

		setTimeout(() => {
			clearInterval(glitch);
			this.el.innerText = this.text;
		}, this.settings.duration);
	}

	handleRandomize(string) {
		let arr = Array.from(string);

		for(let i = 1; i < arr.length; i++) {
			if(arr[i] != " " && i % 3 === 0) {
				arr[i] = this.letters[
					Math.floor( Math.random() * this.letters.length )
				];
			}
		}

		let newString = arr.join("");
		this.el.innerText = newString;
	}
}

export default HackerText;
