"use strict";

/**
 * Imports
 * =============================================================================
 */
import {
	EventEmitter,
	Animation as AnimationManager,
	Resize as ResizeManager
} from "./../../managers";
import Canvas from "./Canvas";
import { breakpoints } from "./../../constants";

/**
 * Class
 * =============================================================================
 */
class DottedGrid {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el, { _index }) {
		this.el = el;
		this.index = _index;

		this.initBinds();
		this.init();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.spacing = 200;
		this.trigger = this.getTrigger();

		this.canvas = new Canvas(this.el.querySelector(".c-dotted-grid__canvas"), {
			width: this.getWidth(),
			height: this.getHeight(),
			spacing: this.spacing,
			dotInverted: this.getDotInverted(),
		});

		this.isResizing = false;

		this.isMobile = this.getMobileState();
		this.isRenderedOnce = false;

		this.draw();
	}

	initBinds() {
		this.draw = this.draw.bind(this);
		this.handleResize = this.handleResize.bind(this);
		this.handleResizeEnded = this.handleResizeEnded.bind(this);
		this.handleDraw = this.handleDraw.bind(this);
	}

	initEvents() {
		AnimationManager.handleAdd(this.el, this.draw);
		ResizeManager.handleAdd(`dotted-grid-${this.index}`, {
			on: this.handleResize,
			end: this.handleResizeEnded
		});
	
		if (this.trigger) {
			EventEmitter.handleAdd(this.trigger, this.handleDraw);
		}
		
	}

	/* ====================================================================== *
	 * Helpers
	 * ====================================================================== */
	getWidth() {
		return this.el.getBoundingClientRect().width + this.spacing;
	}

	getHeight() {
		return this.el.getBoundingClientRect().height + this.spacing;
	}

	getMobileState() {
		return window.innerWidth < breakpoints.mobile;
	}

	getDotInverted() {
		return this.el.getAttribute("data-dot-inverted");
	}

	getTrigger() {
		return this.el.getAttribute("data-dotted-grid-trigger");
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleResize() {
		if (this.isResizing) return;

		this.isMobile = this.getMobileState();

		this.canvas.handleResizeStarted();
		this.isResizing = true;
	}

	handleResizeEnded() {
		if (!this.isResizing) return;

		this.isMobile = this.getMobileState();

		this.canvas.handleResizeEnded({
			width: this.getWidth(),
			height: this.getHeight()
		});

		this.isResizing = false;
		this.isRenderedOnce = false;
	}

	handleDraw(shouldRender) {
		this.shouldRender = shouldRender;
	}

	/* ====================================================================== *
	 * Render cycles
	 * ====================================================================== */
	draw() {
		if (
			this.isResizing ||
			(this.isMobile && this.isRenderedOnce) ||
			(this.trigger && !this.shouldRender)
		) return;

		this.canvas.draw();

		this.isRenderedOnce = true;
	}
}

export default DottedGrid;
