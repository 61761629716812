"use strict";

/**
 * Class
 * =============================================================================
 */
class Root {

	hideContent({
		rootMain
	}) {
		this.hideContentAnim = new TimelineLite({
			delay: 0.9,
			onComplete: () => this.hideContentAnim.kill()
		});

		this.hideContentAnim
			.to(rootMain, 0, { opacity: 0 }, 0);
	}

	showContent({
		rootMain,
	}, completeCb) {
		this.hideContentAnim.kill();

		this.showContentAnim = new TimelineLite({
			delay: 0.9,
			onComplete: () => {
				this.showContentAnim.kill();

				if (completeCb) {
					let completeCbTimeout = setTimeout(() => {
						completeCb();

						clearTimeout(completeCbTimeout);
						completeCbTimeout = null;
					}, 800);
				}
			}
		});

		this.showContentAnim
			// Move and show everything without animating it
			.to(rootMain, 0, { opacity: 1 }, 0);
	}

	updateContent({
		rootMain,
	}) {
		this.hideContentAnim.kill();

		this.updateContentAnim = new TweenLite
			.to(rootMain, 0, { opacity: 0 }, 0);
	}
}

export default Root;
