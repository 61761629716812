"use strict";

/**
 * Class
 * =============================================================================
 */
let instance = null;

class SpriteLight {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor() {
		if (!instance) {
			instance = this;
		}

		this.init();

		return instance;
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.initDimensions();
		this.initSettings();

		this.el = this.initEl();

		this.ctx = this.el.getContext("2d", {
			alpha: false
		});

		this.draw();
	}

	initDimensions() {
		this.width = 10;
		this.height = 10;
	}

	initSettings() {
		this.x = this.width * 0.5;
		this.y = this.width * 0.5;
		this.radius = 1;
		this.fill = "#ffffff";
	}

	initEl() {
		const el = document.createElement("canvas");
		el.width = this.width;
		el.height = this.height;

		return el;
	}

	/* ====================================================================== *
	 * Render cycles
	 * ====================================================================== */
	draw() {
		this.drawArc();
	}

	drawArc() {
		this.ctx.clearRect(0, 0, this.width, this.height);
		this.ctx.beginPath();
		this.ctx.fillStyle = this.fill;
		this.ctx.arc(this.x, this.y, this.radius, 0, Math.PI * 2);
		this.ctx.moveTo(this.x, this.y);
		this.ctx.fill();
		this.ctx.closePath();
	}
}

export default new SpriteLight;
