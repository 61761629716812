"use strict";

/**
 * Class
 * =============================================================================
 */
class Nav {

	openNav({
		overlay,
		background,
		hologram,
		decorator,
		items,
		sign,
		socials
	}) {
		this.openNavAnim = new TimelineLite({
			delay: 0.4,
			onComplete: () => this.openNavAnim.kill()
		});

		this.openNavAnim
			// Appear / Dissapear overlay and background
			.to(overlay, 0.5, { ease: Expo.easeOut, scaleX: 1 }, 0)
			.to(overlay, 1, { ease: Expo.easeInOut, x: "100%" }, 0.5)
			.to(background, 0, { opacity: 1 }, 0.5)
			// Show hologram and decorator
			.to(hologram, 1, { ease: Expo.easeOut, x: 0 }, 0.8)
			.to(hologram, 0, { ease: Expo.easeOut, opacity: 1 }, 0.4)
			.to(decorator, 0, { ease: Expo.easeOut, opacity: 0.5 }, 0.4)
			// Show different items
			.staggerTo(items, 1, { ease: Expo.easeOut, x: 0, opacity: 1 }, 0.06, 0.8)
			// Show sign in button
			.to(sign, 1, { ease: Expo.easeOut, opacity: 0.5, y: 0 }, 0.8)
			// Show different socials
			.staggerTo(socials, 1, { ease: Expo.easeOut, opacity: 0.5, y: 0 }, 0.06, 0.8);
	}

	closeNav({
		overlay,
		background,
		hologram,
		decorator,
		items,
		sign,
		socials
	}) {
		this.openNavAnim.kill();

		this.closeNavAnim = new TimelineLite({
			onComplete: () => {
				this.closeNavAnim.kill();

				TweenLite.set([
					overlay,
					background,
					hologram,
					decorator,
					items,
					sign,
					socials
				], { clearProps: "all" });
			}
		});

		this.closeNavAnim
			// Move overlay to the right and dissapear
			.to(overlay, 0.6, { ease: Expo.easeIn, x: "0%", zIndex: 999 }, 0.4)
			.to(overlay, 1, { ease: Expo.easeInOut, scaleX: 0 }, 0.8)
			.to(background, 0, { opacity: 0 }, 1)
			// .to(glow, 0, { opacity: 0 }, 1)
			// Hide hologram and decorator
			.to(hologram, 0, { opacity: 0 }, 1)
			.to(hologram, 1, { ease: Expo.easeIn, x: "-100px" }, 0)
			.to(decorator, 0, { opacity: 0 }, 1)
			// Hide items
			.staggerTo(items, 1, { ease: Expo.easeIn, x: "-250px", opacity: 0 }, 0.06, 0)
			// Hide sign
			.to(sign, 1, { ease: Expo.easeIn, y: "100px", opacity: 0 }, 0)
			// Hide different socials
			.staggerTo(socials, 1, { ease: Expo.easeIn, y: "100px", opacity: 1 }, 0.06, 0);
	}
}

export default Nav;
