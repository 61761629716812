"use strict";

/**
 * Class
 * ============================================================================
 */
let instance = null;

class Scroll {

	/* ======================================================================= *
	 * Constructor
	 * ======================================================================= */
	constructor() {
		if (!instance) {
			instance = this;
		}

		this.init();

		return instance;
	}

	/* ======================================================================= *
	 * Inits
	 * ======================================================================= */
	init() {
		this.triggers = [];
		this.dist = window.pageYOffset;
		this.dir = "down";
	}

	/* ======================================================================= *
	 * Handlers
	 * ======================================================================= */
	handleAdd({ el, func, up:scrollUpPercent = 0, down:scrollDownPercent = 1 }) {
		const obj = {
			el,
			func,
			scrollUpPercent,
			scrollDownPercent,
			cb: () => this.handleScroll(obj)
		};

		this.triggers.push(obj);

		TweenLite.ticker.addEventListener("tick", obj.cb);
	}

	handleRemove(obj) {
		this.triggers = this.triggers.filter((item) => item !== obj);

		TweenLite.ticker.removeEventListener("tick", obj.cb);
	}

	handleScroll(obj) {
		const { el, func, scrollUpPercent, scrollDownPercent } = obj;

		const bounding = el.getBoundingClientRect();
		const dist = window.pageYOffset;
		const dir = dist === this.dist ? (
			this.dir
		) : (
			dist >= this.dist ? "down" : "up"
		);

		if (
			(dir === "down" &&
			bounding.top <= (window.innerHeight * scrollDownPercent)
			&& bounding.top >= 0) ||
			(dir === "up" &&
			bounding.top + bounding.height >= window.innerHeight * scrollUpPercent &&
			bounding.top + bounding.height >= 0)
		) {
			func();
			this.handleRemove(obj);
		}

		this.dist = dist;
		this.dir = dir;
	}

	handleDestroy() {
		for (let i = 0; i < this.triggers.length; i++) {
			const trigger = this.triggers[i];

			TweenLite.ticker.removeEventListener("tick", trigger.cb);
		}

		this.triggers = [];
	}
}

export default new Scroll;
