"use strict";

/**
 * Init all
 * =============================================================================
 */
const initAll = (selector, jsClass, params = {}) => {
	const els = document.querySelectorAll(selector);
	let result = [];

	if (!els || els.length <= 0) {
		return null;
	}

	for (let i = 0; i < els.length; i++) {
		params._index = i;

		const el = new jsClass(els[i], params);

		result.push(el);
	}

	return result;
};

/**
 * Init
 * =============================================================================
 */
const init = (selector, jsClass, params) => {
	const el = document.querySelector(selector);

	if (!el) {
		return;
	}

	return new jsClass(el, params);
};

/**
 * IsInView
 * ============================================================================
 */
const isInView = (el) => {
	const bounding = el.getBoundingClientRect();

	return (
		bounding.top <= window.innerHeight &&
		bounding.top + bounding.height > 0
	);
  };

export default {
	initAll,
	init,
	isInView,
};
