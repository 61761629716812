"use strict";

/**
 * Imports
 * =============================================================================
 */
import { Vector } from "./../../../extends";
import SpriteDark from "../sprites/SpriteDark";
import SpriteLight from "../sprites/SpriteLight";


/**
 * Class
 * =============================================================================
 */
class Dot {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(ctx, {
		x, y,
		index,
		inverted
	}) {
		this.ctx = ctx;
		this.index = index;
		this.inverted = inverted;

		this.position = new Vector({ x, y });
		this.original = new Vector({ x, y });
		this.velocity = new Vector({ x: 0, y: 0 });
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleCollision({ expandDistance, spring, mouse }) {
		const delta = this.position
			.clone()
			.subtract(mouse);

		const dist = Math.sqrt((delta.x * delta.x) + (delta.y * delta.y));

		if (dist >= expandDistance) return;

		const angle = delta.angle();
		const target = mouse
			.clone()
			.add({
				x: Math.cos(angle) * expandDistance,
				y: Math.sin(angle) * expandDistance
			});

		this.velocity
			.add(target
				.subtract(this.position)
				.scale(spring)
			);
	}

	handlePosition({ spring, friction }) {
		const delta = this.original
			.clone()
			.subtract(this.position);

		const acceleration = delta
			.clone()
			.scale(spring);

		this.velocity.add(acceleration);
		this.velocity.scale(friction);

		this.position.add(this.velocity);
	}

	/* ====================================================================== *
	 * Render cycles
	 * ====================================================================== */
	update(data) {
		this.handleCollision(data);
		this.handlePosition(data);
	}

	draw() {
		this.ctx.drawImage(this.inverted ? SpriteLight.el : SpriteDark.el, this.position.x, this.position.y);
	}
}

export default Dot;
