"use strict";

/**
 * Imports
 * =============================================================================
 */
import { dom } from "../utils";

/**
 * Class
 * =============================================================================
 */
let instance = null;

class Animation {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor() {
		if (!instance) {
			instance = this;
		}

		this.init();

		return instance;
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.renderer = [];
	}
	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleAdd(el, func) {
		const obj = {
			el,
			func,
			cb: () => this.handleDraw(el, func)
		};

		this.renderer.push(obj);

		TweenLite.ticker.addEventListener("tick", obj.cb);
	}

	handleRemove(el, func) {
		const obj = this.renderer.find((r) => el === r.el && func === r.func);

		this.renderer = this.renderer.filter((item) => item !== obj);

		TweenLite.ticker.removeEventListener("tick", obj.cb);
	}

	handleDraw(el, cb) {
		if (el && !dom.isInView(el)) return;

		if (cb) cb();
	}

	handleDestroy() {
		for (let i = 0; i < this.renderer.length; i++) {
			const render = this.renderer[i];

			TweenLite.ticker.removeEventListener("tick", render.cb);
		}

		this.renderer = [];
	}
}

export default new Animation;
