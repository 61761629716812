"use strict";

/**
 * Class
 * =============================================================================
 */
class Background {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(ctx, {
		width,
		height,
	}) {
		this.ctx = ctx;
		this.width = width;
		this.height = height;

		this.init();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.color = "rgba(255, 255, 255, 0)";
	}

	/* ====================================================================== *
	 * Main render
	 * ====================================================================== */
	draw() {
		this.ctx.clearRect(0, 0, this.width, this.height);
		this.ctx.beginPath();
		this.ctx.fillStyle = this.color;
		this.ctx.fillRect(0, 0, this.width, this.height);
		this.ctx.fill();
		this.ctx.closePath();
	}
}

export default Background;
