"use strict";

/**
 * Imports
 * =============================================================================
 */


/**
 * Class
 * =============================================================================
 */
class EditionsGroup {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		this.el = el;

		this.initBinds();
		this.init();
		this.initEvents();
	}

	initBinds() {
		this.handleResponsiveCarousel = this.handleResponsiveCarousel.bind(this);
	}

	init() {
		this.glide = new Glide(".glide", {
			perView: 1.5,
			gap: 40,
			rewind: false
		});

		this.handleResponsiveCarousel();
	}

	initEvents() {
		window.addEventListener("resize", this.handleResponsiveCarousel);
	}

	handleResponsiveCarousel() {
		this.windowWidth = this.getWindowWidth();

		if (this.windowWidth <= 740) {
			this.glide.mount();
		}
	}

	getWindowWidth() {
		return window.innerWidth;
	}
}

export default EditionsGroup;
