"use strict";

/**
 * Class
 * =============================================================================
 */
class Vector {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor({ x, y }) {
		this.x = x;
		this.y = y;
	}

	/* ====================================================================== *
	 * Methods
	 * ====================================================================== */
	add(vector) {
		const { x, y } = vector;

		this.x += x;
		this.y += y;

		return this.clone();
	}

	subtract(vector) {
		const { x, y } = vector;

		this.x -= x;
		this.y -= y;

		return this.clone();
	}

	multiply(vector) {
		const { x, y } = vector;

		this.x *= x;
		this.y *= y;

		return this.clone();
	}

	divide(vector) {
		const { x, y } = vector;

		this.x /= x;
		this.y /= y;

		return this.clone();
	}

	scale(factor) {
		this.x *= factor;
		this.y *= factor;

		return this.clone();
	}

	magnitude() {
		return Math.sqrt((this.x + this.y) * (this.x + this.y));
	}

	distance(vector) {
		return Math.abs(
			Math.sqrt(
				((this.x - vector.x) * (this.x - vector.x)) +
				((this.y - vector.y) * (this.y - vector.y))
			)
		);
	}

	angle() {
		return Math.atan2(this.y, this.x);
	}

	degrees() {
		const angle = this.angle();

		const degrees = 180 * angle / Math.PI;

		return (360+Math.round(degrees)) % 360;
	}

	clone() {
		return new Vector({
			x: this.x,
			y: this.y
		});
	}
}

export default Vector;
