"use strict";

/**
 * Imports
 * =============================================================================
 */
import Container from "./Container";

/**
 * Class
 * =============================================================================
 */
class Content {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		this.el = el;

		this.init();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.container = new Container(this.el.querySelector(".c-profile-mobile-menu__container"));

		this.height = this.container.height;
	}

	handleOpen() {
		if (this.closeTimeout) {
			clearTimeout(this.closeTimeout);
			this.closeTimeout = null;
		}

		if (this.clearStyleTimeout) {
			clearTimeout(this.clearStyleTimeout);
			this.clearStyleTimeout = null;
		}

		this.el.style.height = `${this.height}px`;

		this.clearStyleTimeout = setTimeout(() => {
			this.el.removeAttribute("style");

			clearTimeout(this.clearStyleTimeout);
			this.clearStyleTimeout = null;
		}, 300);
	}

	handleClose() {
		if (this.clearStyleTimeout) {
			clearTimeout(this.clearStyleTimeout);
			this.clearStyleTimeout = null;
		}

		if (this.closeTimeout) {
			clearTimeout(this.closeTimeout);
			this.closeTimeout = null;
		}

		this.height;

		this.el.style.height = `${this.height}px`;

		this.closeTimeout = setTimeout(() => {
			this.el.style.height = 0;

			clearTimeout(this.closeTimeout);
			this.closeTimeout = null;
		}, 0);
	}
}

export default Content;
