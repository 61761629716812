"use strict";

/**
 * Imports
 * =============================================================================
 */
import Background from "./objects/Background";
import Dots from "./objects/Dots";
import { Mouse } from "./../../managers";
import { Vector } from "./../../extends";

/**
 * Class
 * =============================================================================
 */
class Canvas {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el, {
		width,
		height,
		spacing,
		dotInverted
	}) {
		this.el = el;
		this.width = width;
		this.height = height;
		this.spacing = spacing;
		this.dotInverted = dotInverted;

		this.initBinds();
		this.init();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.el.width = this.width;
		this.el.height = this.height;
		this.top = this.getTop();
		this.left = this.getLeft();

		this.ctx = this.el.getContext("2d", {
			alpha: true
		});

		this.background = new Background(this.ctx, {
			width: this.width,
			height: this.height,
		});

		this.dots = new Dots(this.ctx, {
			width: this.width - this.spacing,
			height: this.height - this.spacing,
			spacing: this.spacing,
			interval: 15,
			inverted: this.dotInverted
		});
	}

	initBinds() {
		this.handleClick = this.handleClick.bind(this);
	}

	initEvents() {
		window.addEventListener("click", this.handleClick);
	}

	/* ======================================================================= *
	* Helpers
	* ======================================================================= */
	getTop() {
		return this.el.getBoundingClientRect().top;
	}

	getLeft() {
		return this.el.getBoundingClientRect().left;
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleClick() {
		const x = Mouse.x - this.left;
		const y = Mouse.y - this.top;

		if (x < 0 || x > this.width || y < 0 || y > this.height) return;

		this.dots.handleExpand();
	}

	handleResizeStarted() {
		this.el.width = 0;
		this.el.height = 0;

		this.reset();
	}

	handleResizeEnded({ width, height }) {
		this.width = width;
		this.height = height;

		this.init();
	}

	/* ====================================================================== *
	 * Render cycles
	 * ====================================================================== */
	draw() {
		this.top = this.getTop();
		this.left = this.getLeft();

		const mouse = new Vector({
			x: Mouse.x - this.left,
			y: Mouse.y - this.top
		});

		this.background.draw();
		this.dots.draw(mouse);
	}

	reset() {
		this.background = null;
		this.dots.destroy();
		this.dots = null;
	}
}

export default Canvas;
