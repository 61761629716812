"use strict";

/**
 * Imports
 * =============================================================================
 */
import Dot from "./Dot";
import { DottedGridAnimations as Animation } from "./../../../animations";
import { math } from "./../../../utils";

/**
 * Class
 * =============================================================================
 */
class Dots {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(ctx, {
		width,
		height,
		spacing,
		interval,
		inverted
	}) {
		this.ctx = ctx;
		this.width = width;
		this.height = height;
		this.spacing = spacing;
		this.interval = interval;
		this.inverted = inverted;

		this.init();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.items = this.initItems();

		this.friction = 0.8;
		this.spring = 0.05;
		this.minDistance = 100;
		this.distance = this.minDistance;
		this.maxDistance = 300;

		this.animation = new Animation();
	}

	initItems() {
		const xAmount = Math.ceil(this.width / this.interval);
		const yAmount = Math.ceil(this.height / this.interval);

		const items = [];

		for (let i = 0; i < xAmount; i++) {
			const row = i;

			for (let j = 0; j < yAmount; j++) {
				const col = j;
				const radius = 1;
				const x = (this.spacing * 0.5) + Math.floor((row * this.interval) + radius);
				const y = (this.spacing * 0.5) + Math.floor((col * this.interval) + radius);

				const dot = new Dot(this.ctx, {
					x, y,
					index: j,
					inverted: this.inverted
				});

				items.push(dot);
			}
		}

		return items;
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleExpand() {
		this.animation.expandDetection({
			dots: this,
			amount: math.map(this.maxDistance - this.distance, this.maxDistance, this.minDistance, 100, 0)
		});
	}

	/* ====================================================================== *
	 * Render cycles
	 * ====================================================================== */
	draw(mouse) {
		for (let i = 0; i < this.items.length; i++) {
			const dot = this.items[i];

			dot.update({
				friction: this.friction,
				spring: this.spring,
				expandDistance: this.distance,
				mouse,
			});

			dot.draw();
		}
	}

	destroy() {
		this.animation.killAll();
		this.items = null;
	}
}

export default Dots;
