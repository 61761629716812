"use strict";

/**
 * Map
 * =============================================================================
 */
const map = (value, low1, high1, low2, high2) => {
  return low2 + (high2 - low2) * (value - low1) / (high1 - low1);
};

/**
 * RandomBetween
 * =============================================================================
 */
const randomBetween = (min, max) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

export default {
  map,
  randomBetween
};
