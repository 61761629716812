"use strict";

/**
 * Class
 * =============================================================================
 */
class Hamburger {

	transformToClose({
		barFirst,
		barMiddle,
		barLast,
		close,
	}) {
		this.transformToCloseAnim = new TimelineLite({
			onComplete: () => this.transformToCloseAnim.kill()
		});

		this.transformToCloseAnim
			// Move top bar to the left
			.to(barFirst, 0.3, { ease: Expo.easeOut, scaleX: 0.1 }, 0)
			.to(barFirst, 0.3, { ease: Expo.easeOut, x: -10 }, 0)
			// Move bottom bar to the right
			.to(barLast, 0.3, { ease: Expo.easeOut, scaleX: 0.1 }, 0)
			.to(barLast, 0.3, { ease: Expo.easeOut, x: 10 }, 0)
			// Middle bars takes up entire space
			.to(barMiddle, 0.5, { ease: Expo.easeOut, scaleX: 1 }, 0)
			.to(barMiddle, 0.5, { ease: Expo.easeOut, transformOrigin: "center" }, 0)
			// Middle vertical bar appears
			.to(close, 0.6, { ease: Expo.easeOut, scaleY: 1 }, 0)
			// Top bar moves to the right and hits vertical middle
			.to(barFirst, 0.2, { ease: Expo.easeIn, scaleX: 1.5 }, 0.2)
			.to(barFirst, 0, { transformOrigin: "right center" }, 0.4)
			.to(barFirst, 0.2, { ease: Expo.easeOut, x: 30 }, 0.4)
			.to(barFirst, 0.2, { ease: Expo.easeOut, scaleX: 0 }, 0.4)
			// Top bar moves to the left and hits vertical middle
			.to(barLast, 0.2, { ease: Expo.easeIn, scaleX: 1.5 }, 0.2)
			.to(barLast, 0, { transformOrigin: "left center" }, 0.4)
			.to(barLast, 0.2, { ease: Expo.easeOut, x: -30 }, 0.4)
			.to(barLast, 0.2, { ease: Expo.easeOut, scaleX: 0 }, 0.4)
			// Plus sign rotates to close
			.to(barMiddle, 1, { ease: Back.easeOut.config(0.7), rotation: 315 }, 0.4)
			.to(close, 1, { ease: Back.easeOut.config(0.7), rotation: 315 }, 0.4);
	}

	transformToBase({
		barFirst,
		barMiddle,
		barLast,
		close,
	}) {
		this.transformToCloseAnim.kill();

		this.transformToBaseAnim = new TimelineLite({
			onComplete: () => {
				this.transformToBaseAnim.kill();
				TweenLite.set([
					barFirst,
					barMiddle,
					barLast,
					close
				], { clearProps: "all" });
			}
		});

		this.transformToBaseAnim
			// Rotate the bar middle and the vertical close bar
			.to(barMiddle, 1, { ease: Back.easeInOut.config(1.2), rotation: 0 }, 0)
			.to(close, 1, { ease: Back.easeInOut.config(1.2), rotation: 0 }, 0)
			// Clear the vertical close bar
			.to(close, 0.3, { ease: Expo.easeIn, scaleY: 0 }, 0.2)
			// Top bar appears
			.to(barFirst, 0, { x: 0, transformOrigin: "left center" }, 0)
			.to(barFirst, 0.5, { ease: Expo.easeOut, scaleX: 1 }, 0.6)
			// Middle bar resizes
			.to(barMiddle, 0, { transformOrigin: "right center" }, 0.7)
			.to(barMiddle, 0.5, { ease: Expo.easeOut, scaleX: 0.78 }, 0.7)
			// Bottom bar appears
			.to(barLast, 0, { x: 0, transformOrigin: "right center" }, 0)
			.to(barLast, 0.5, { ease: Expo.easeOut, scaleX: 0.56 }, 0.8);
	}
}

export default Hamburger;
