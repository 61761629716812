"use strict";

/**
 * Class
 * =============================================================================
 */
class DottedGrid {

	expandDetection({ dots, amount }) {
		if (this.expandDetectionAnim) this.expandDetectionAnim.kill();

		this.expandDetectionAnim = new TimelineLite({
			onComplete: () => this.expandDetectionAnim
		});

		this.expandDetectionAnim
			.to(dots, 0.1, { distance: dots.distance + amount, ease: Expo.easeOut, }, 0)
			.to(dots, 1, { distance: dots.minDistance, ease: Elastic.easeOut.config(1, 0.6) }, 0.3);
	}

	killAll() {
		if (this.expandDetectionAnim) {
			this.expandDetectionAnim.kill();
			this.expandDetectionAnim = null;
		}
	}
}

export default DottedGrid;
