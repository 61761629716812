"use strict";

/**
 * Imports
 * =============================================================================
 */
import { Toggle } from "./../../extends";
import { EventEmitter } from "../../managers";
import { HamburgerAnimations as Animation } from "./../../animations";

/**
 * Class
 * =============================================================================
 */
class Hamburger extends Toggle {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		super("is-closed");

		this.el = el;

		this.initBinds();
		this.init();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		super.init();

		this.animation = new Animation();
	}

	initBinds() {
		this.handleToggle = this.handleToggle.bind(this);
		this.handleEnable = this.handleEnable.bind(this);
	}

	initEvents() {
		this.el.addEventListener("click", this.handleToggle);
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleToggle(e) {
		super.handleToggle(e);

		EventEmitter.handleEmit("hamburger-toggle", this.isToggled);
	}

	handleEnable() {
		super.handleEnable();

		this.animation.transformToClose({
			barFirst: this.el.querySelector(".c-hamburger__bar--first"),
			barMiddle: this.el.querySelector(".c-hamburger__bar--middle"),
			barLast: this.el.querySelector(".c-hamburger__bar--last"),
			close: this.el.querySelector(".c-hamburger__close"),
		});
	}

	handleDisable() {
		super.handleDisable();

		this.animation.transformToBase({
			barFirst: this.el.querySelector(".c-hamburger__bar--first"),
			barMiddle: this.el.querySelector(".c-hamburger__bar--middle"),
			barLast: this.el.querySelector(".c-hamburger__bar--last"),
			close: this.el.querySelector(".c-hamburger__close"),
		});
	}
}

export default Hamburger;
