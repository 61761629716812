/**
 * Debounce
 * =============================================================================
 */
const debounce = (func, delay) => {
	let timeout;

	return (...args) => {
		const ctx = debounce;

		clearTimeout(timeout);
		timeout = setTimeout(() => func.apply(ctx, args), delay);
	};
};

export default {
  debounce
};
