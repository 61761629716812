"use strict";

/**
 * Imports
 * =============================================================================
 */
import { events } from "./../utils";

/**
 * Class
 * =============================================================================
 */
let instance = null;

class Resize {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor() {
		if (!instance) {
			instance = this;
		}

		this.initBinds();
		this.initEvents();
		this.init();

		return instance;
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.callbacks = {};
	}

	initBinds() {
		this.handleResize = this.handleResize.bind(this);
	}

	initEvents() {
		window.addEventListener("resize", events.debounce(this.handleResize, 150));
	}

	/* ====================================================================== *
	 * Helpers
	 * ====================================================================== */
	hasListener(label) {
		return this.callbacks[label] ? true : false;
	}

	getListener(label) {
		return this.callbacks[label] || null;
	}

	isFunction(obj) {
		return typeof obj == "function" || false;
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleAdd(id, cbs) {
		if (this.hasListener(id)) return;

		this.callbacks[id] = cbs;
	}

	handleResize() {
		clearTimeout(this.resizeTimeout);
		this.resizeTimeout = null;

		const keys = Object.keys(this.callbacks);

		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			const cb = this.callbacks[key].on || null;

			if (cb) cb();
		}

		if (this.resizeTimeout) return;

		this.resizeTimeout = setTimeout(() => {
			this.handleEnd();
		}, 600);
	}

	handleEnd() {
		clearTimeout(this.resizeTimeout);
		this.resizeTimeout = null;

		const keys = Object.keys(this.callbacks);

		for (let i = 0; i < keys.length; i++) {
			const key = keys[i];
			const cb = this.callbacks[key].end || null;

			if (cb) cb();
		}
	}
}

export default new Resize;
