"use strict";

/**
 * Imports
 * =============================================================================
 */
import Button from "./Button";
import Content from "./Content";
import { Toggle } from "../../extends";
import { EventEmitter } from "../../managers";

/**
 * Class
 * =============================================================================
 */
class ProfileMobileMenu extends Toggle {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		super("is-open");

		this.el = el;

		this.initBinds();
		this.init();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.group = this.getGroup();

		this.button = new Button(this.el.querySelector(".c-profile-mobile-menu__header"), {
			clickCb: this.handleToggle
		});

		this.content = new Content(this.el.querySelector(".c-profile-mobile-menu__content"));
	}

	initBinds() {
		this.handleToggle = this.handleToggle.bind(this);
		this.handleEnable = this.handleEnable.bind(this);
		this.handleDisable = this.handleDisable.bind(this);
		this.handleReset = this.handleReset.bind(this);
	}

	initEvents() {
		if (this.group) {
			EventEmitter.handleAdd(`reset-profile-mobile-menu-group:${this.group}`, this.handleReset);
		}
	}

	/* ====================================================================== *
	 * Helpers
	 * ====================================================================== */
	getGroup() {
		return this.el.getAttribute("data-profile-mobile-menu-group");
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleEnable() {
		this.content.handleOpen();

		super.handleEnable();

		if (this.group) {
			EventEmitter.handleEmit(`reset-profile-mobile-menu-group:${this.group}`, {
				el: this.el
			});
		}
	}

	handleDisable() {
		this.content.handleClose();

		super.handleDisable();
	}

	handleReset({ el }) {
		if (el === this.el) {
			return;
		}

		this.handleDisable();
	}
}

export default ProfileMobileMenu;
