"use strict";

/**
 * Class
 * =============================================================================
 */
class List {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		this.el = el;

		this.init();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.width = this.getWidth();
	}

	/* ====================================================================== *
	 * Helpers
	 * ====================================================================== */
	getWidth() {
		return this.el.getBoundingClientRect().width;
	}

	/* ====================================================================== *
	 * HandleResize
	 * ====================================================================== */
	handleResize() {
		this.init();
	}
}

export default List;
