"use strict";

/**
 * Class
 * ============================================================================
 */
let instance = null;

class Mouse {

	/* ======================================================================= *
	 * Constructor
	 * ======================================================================= */
	constructor() {
		if (!instance) {
			instance = this;
		}

		this.initBinds();
		this.init();
		this.initEvents();

		return instance;
	}

	/* ======================================================================= *
	 * Inits
	 * ======================================================================= */
	init() {
		this.x = 0;
		this.y = 0;
	}

	initBinds() {
		this.handleMouseMove = this.handleMouseMove.bind(this);
	}

	initEvents() {
		window.addEventListener("mousemove", this.handleMouseMove);
	}

	/* ======================================================================= *
	 * Handlers
	 * ======================================================================= */
	handleMouseMove(e) {
		this.x = e.clientX;
		this.y = e.clientY;
	}
}

export default new Mouse;
