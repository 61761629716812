"use strict";

/**
 * Class
 * =============================================================================
 */
class Container {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		this.el = el;

		this.init();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.height = this.getHeight();
	}

	/* ====================================================================== *
	 * Helpers
	 * ====================================================================== */
	getHeight() {
		return this.el.getBoundingClientRect().height;
	}
}

export default Container;
