"use strict";

/**
 * Imports
 * =============================================================================
 */
import Button from "./Button";
import { Toggle } from "./../../extends";
import { Root } from "./../../managers";

/**
 * Class
 * =============================================================================
 */
class Overlay extends Toggle {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		super("is-open");

		this.el = el;

		this.initBinds();
		this.init();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.id = this.getId();

		this.buttons = this.initButtons();
	}

	initBinds() {
		this.handleEnable = this.handleEnable.bind(this);
		this.handleDisable = this.handleDisable.bind(this);
	}

	initButtons() {
		const els = document.querySelectorAll(`.js-overlay-trigger[data-overlay-trigger="${this.id}"]`);

		let result = [];

		for (let i = 0; i < els.length; i++) {
			const el = els[i];
			const type = el.getAttribute("data-overlay-type");

			console.log(type);

			result.push(new Button(el, {
				clickCb: type === "open"
					? this.handleEnable
					: this.handleDisable
			}));
		}

		return result;
	}

	/* ====================================================================== *
	 * Helpers
	 * ====================================================================== */
	getId() {
		return this.el.getAttribute("id");
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleEnable() {
		super.handleEnable();

		Root.handleAddClass("has-overlay-open");
	}

	handleDisable() {
		super.handleDisable();

		Root.handleRemoveClass("has-overlay-open");
	}
}

export default Overlay;
