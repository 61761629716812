"use strict";

/**
 * Imports
 * =============================================================================
 */
import { Toggle } from "./../../extends";
import {
	EventEmitter,
	Resize as ResizeManager
} from "../../managers";
import List from "./List";
import { NavAnimations as Animation } from "./../../animations";

/**
 * Class
 * =============================================================================
 */
class Nav extends Toggle {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		super("is-open");

		this.el = el;

		this.initBinds();
		this.init();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		super.init();

		this.list = new List(this.el.querySelector(".c-nav__list"));

		this.animation = new Animation();

		EventEmitter.handleAdd("header-init", this.handleAddSpacing);
		EventEmitter.handleAdd("header-resize", this.handleAddSpacing);
	}

	initBinds() {
		this.handleToggle = this.handleToggle.bind(this);
		this.handleResize = this.handleResize.bind(this);
		this.handleAddSpacing = this.handleAddSpacing.bind(this);
	}

	initEvents() {
		EventEmitter.handleAdd("hamburger-toggle", this.handleToggle);
		ResizeManager.handleAdd("nav", {
			on: this.handleResize
		});
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleToggle(isHamburgerClosed) {
		if (isHamburgerClosed) {
			this.handleEnable();
		} else {
			this.handleDisable();
		}
	}

	handleEnable() {
		super.handleEnable();

		EventEmitter.handleEmit("nav-open", this.list.width);

		this.animation.openNav({
			overlay: this.el.querySelector(".c-nav__overlay"),
			background: this.el.querySelector(".c-nav__background"),
			hologram: this.el.querySelector(".c-nav__hologram-container"),
			decorator: this.el.querySelector(".c-nav__decorator"),
			items: this.el.querySelectorAll(".c-nav__item"),
			sign: this.el.querySelector(".c-nav__sign"),
			socials: this.el.querySelectorAll(".c-nav__socials .c-socials__item")
		});
	}

	handleDisable() {
		super.handleDisable();

		EventEmitter.handleEmit("nav-close");

		this.animation.closeNav({
			overlay: this.el.querySelector(".c-nav__overlay"),
			background: this.el.querySelector(".c-nav__background"),
			hologram: this.el.querySelector(".c-nav__hologram-container"),
			decorator: this.el.querySelector(".c-nav__decorator"),
			items: this.el.querySelectorAll(".c-nav__item"),
			sign: this.el.querySelector(".c-nav__sign"),
			socials: this.el.querySelectorAll(".c-nav__socials .c-socials__item")
		});
	}

	handleAddSpacing(spacing) {
		this.el.style.paddingTop = `${spacing}px`;
	}

	handleResize() {
		this.list.handleResize();

		if (!this.isToggled) return;

		EventEmitter.handleEmit("nav-resize", this.list.width);
	}
}

export default Nav;
