"use strict";

/**
 * Imports
 * =============================================================================
 */
// import { Root } from "./../../managers";
import {
	EventEmitter,
	Resize as ResizeManager,
	Animation as AnimationManager,
} from "./../../managers";

/**
 * Class
 * =============================================================================
 */
class Header {

	/* ====================================================================== *
	 * Constructor
	 * ====================================================================== */
	constructor(el) {
		this.el = el;

		this.initBinds();
		this.init();
		this.initEvents();
	}

	/* ====================================================================== *
	 * Inits
	 * ====================================================================== */
	init() {
		this.height = this.getHeight();
		this.scrollOffset = 100;

		EventEmitter.handleEmit("header-init", this.height);
	}

	initBinds() {
		this.handleResize = this.handleResize.bind(this);
		this.handleSticky = this.handleSticky.bind(this);
		this.handleHamburgerToggle = this.handleHamburgerToggle.bind(this);
	}

	initEvents() {
		AnimationManager.handleAdd(null, this.handleSticky);
		ResizeManager.handleAdd("header", {
			on: this.handleResize
		});
		EventEmitter.handleAdd("hamburger-toggle", this.handleHamburgerToggle);
	}

	/* ====================================================================== *
	 * Helpers
	 * ====================================================================== */
	getHeight() {
		return this.el.getBoundingClientRect().height;
	}

	/* ====================================================================== *
	 * Handlers
	 * ====================================================================== */
	handleSticky() {
		if (this.isNavOpen) {
			this.handleShow();
			return;
		}

		const scrollDist = window.pageYOffset;
		const scrollDir = scrollDist === this.scrollDist ? (
			this.scrollDir
		) : (
			scrollDist >= this.scrollDist ? "down" : "up"
		);

		if (scrollDist < window.innerHeight * 0.5) {
			this.handleStatic();
			return;
		}

		if (scrollDir !== this.scrollDir) this.handleStickyState(scrollDir);

		this.scrollDir = scrollDir;
		this.scrollDist = scrollDist;
	}

	handleStickyState(dir) {
		if (dir === "down") {
			this.handleHide();
		} else if (dir === "up") {
			this.handleShow();
			this.handleFixed();
		}
	}

	handleShow() {
		this.el.classList.remove("is-hidden");
	}

	handleHide() {
		this.el.classList.add("is-hidden");
	}

	handleFixed() {
		this.el.classList.add("is-fixed");
	}

	handleStatic() {
		this.el.classList.remove("is-fixed");
	}

	handleHamburgerToggle(isOpen) {
		this.isNavOpen = isOpen;

		if (isOpen) {
			this.handleNavOpen();
		} else {
			this.handleNavClose();
		}
	}

	handleNavOpen() {
		this.el.classList.add("has-nav-open");
		this.isNavOpen = true;
	}

	handleNavClose() {
		this.el.classList.remove("has-nav-open");
		this.isNavOpen = false;
	}

	handleResize() {
		const newHeight = this.getHeight();

		if (this.height !== newHeight) {
			EventEmitter.handleEmit("header-resize", this.height);
		}

		this.height = newHeight;
	}
}

export default Header;
